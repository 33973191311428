
.about_headline{
    color:black;
    text-decoration: none;
    font-family:'Montserrat';
    font-size:30px;
}

.about{
    color:black;
    text-decoration: none;
    font-family:'Montserrat';
    font-size:22px;
}

.hr_about{
    background-color: black;
    height:2px;
    border:steelblue solid 1px;
}

.hr_article{
    background-color: black;
    height:2px;
    border:steelblue solid 1px;
}

.article_body_font{
    font-family:'Montserrat';
    font-size:14px;
    color:black;
}

.blog_list_headline{
    color:black;
    text-decoration: none;
    font-family:'Montserrat';
    font-size:30px;
}

.rounded_content_block{
    border-radius:25px;
    background-color: #fff;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  
.custom-toggler.navbar-toggler {
    border-color: rgba(255,255,255, 1.0);
  }

.drop-shadow {
    position:relative;
    float:left;
    width:40%;
    padding:1em;
    margin:2em 10px 4em;
    background:#fff;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.drop-shadow:before,
.drop-shadow:after {
    content:"";
    position:absolute;
    z-index:-2;
}

.drop-shadow p {
    font-size:16px;
    font-weight:bold;
}
.raised {
    -webkit-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.resources_list_header{
    font-size:13px;
}

.resources_list_primary_item{
    font-size:12px;
}

.resources_list_secondary_item{
    font-size:11px;
}

.article_byline
{
    font-size:10px;
    color:#d4d4d4;
    text-decoration: none;
}

.twitter_community_alert{
    font-size: 14px;
    color: red;
}

.twitter_community_name
{
    font-size: 16px;
    font-weight: bold;
}

.twitter_community_desc
{
    font-size: 14px;
}

.twitter_community_general
{
    font-size: 14px;
}

.twitter_community_guide_headline
{
    font-size: 14px;
    font-weight: bold;
}

.twitter_community_guide_general{
    font-size: 13px;
}

.twitter_community_guide_blurb
{
    font-size: 12px;
    font-style: italic;
}

.twitter_community_box{
    border:solid 1px #d4d4d4;
    box-shadow: none;
    min-height: 380px;
}

.twitter_community_box:hover{
    /*border:solid 1px blue; */
    box-shadow: 2px 2px 3px 3px #d4d4d4;
}

.twitter_community_img
{
    width: 200px;
}

.twitter_community_img_boxed
{
    width: 100%;
}

.rect-img-container {
    position: relative;
}
  
.rect-img-container::after {
    content: "";
    display: block;
    padding-bottom: 10%;
}

.rect-img {
    position: absolute;
    width: 100%;
    height: 25%;
    object-fit: cover;
}

.resources_header_general
{
    font-size: 14px;
    font-weight: bold;
}

.resources_body_general
{
    font-size: 13px;
}

.box_shadow {
    box-shadow: 2px 2px 3px 3px #d4d4d4;
}

.product_title{
    font-size:12px;
    font-weight: bold;
}

.product_text_general{
    font-size:11px;
}

.twitter_category_button
{
    margin-top: 3px;
    margin-bottom: 3px;
}

.twitter_category_see_more_button
{
    margin-top: 3px;
    margin-bottom: 3px;
}

.modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2.5em 1.5em 1.5em 1.5em;
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media screen and (min-width: 500px) {
    /* Center the Modal! */
    .modal-area {
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      max-width: 30em;
      max-height: calc(100% - 1em);
    }
  }
  
  ._modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    line-height: 1;
    background: #f6f6f7;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
  }
  
  ._modal-close-icon {
    width: 25px;
    height: 25px;
    fill: transparent;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 2;
  }
  
  .modal-body {
    padding-top: 0.25em;
  }
  
  ._hide-visual {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  
  .scroll-lock {
    overflow: hidden;
    margin-right: 17px;
  }

@media (min-width: 280px) {  
    .single_page_post {top:60px} 
    .blog_list_main {top:60px} 
    .article_content_box { padding: 15px; } 
    .article_date { font-size:12px;}
}

@media (min-width: 544px) {  
    .single_page_post {top:70px} 
    .blog_list_main {top:70px} 
    .article_content_box { padding: 15px;}
    .article_date { font-size:12px;}
}
   
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
    .single_page_post {top:100px;} 
    .blog_list_main {top:100px;} 
    .article_content_box { padding: 20px;}
    .article_date { font-size:14px;}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .single_page_post {top:130px} 
    .blog_list_main {top:130px;} 
    .article_content_box { padding: 20px;}
    .article_date { font-size:14px;}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .single_page_post {top:140px}   
    .blog_list_main {top:140px;} 
    .article_content_box { padding: 30px;}
    .article_date { font-size:14px;}
}