
.headerMain{
    color: white;
}

.headerNavLink{
    color:white;
    text-decoration: none;
    font-family:'Roboto Condensed';
    font-size:24px;
}

.headerNavLink:hover {
    color: rgb(122, 122, 122);
  }

.header_main_row {
    background-color:'#000'; 
    height:'170px'; 
    padding:'10px'; 
    position:'fixed'; 
    top:'0px'; 
    width:'100%';
}

@media (min-width: 280px) {
    .header_display_title {font-size:2.5rem;} /*1rem = 16px*/
    .header_img { height:3.5rem; }
}

@media (min-width: 544px) {  
    .header_display_title {font-size:2rem;} /*1rem = 16px*/
    .header_img { height:5rem; }
    .header_height { height:70px; }
    .header_style { max-height: 70px; background-color:#000; padding:0px}
}
   
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
    .header_display_title {font-size:3rem;} /*1rem = 16px*/
    .header_img {height:6rem;}
    .header_height { height:100px; }
    .header_style { max-height: 100px; background-color:#000; padding:0px}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .header_display_title {font-size:3.5rem;} /*1rem = 16px*/
    .header_img { height:7rem; }
    .header_height { height:130px; }
    .header_style { max-height: 130px; background-color:#000; padding:6px}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .header_display_title {font-size:4rem;} /*1rem = 16px*/    
    .header_img { height:10rem; }
    .header_height { height:140px}
    .header_style { max-height: 170px; background-color:#000; padding:10px}
}

